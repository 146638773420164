import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { changePassword } from '../../features/authSlice';

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [data, setData] = useState({
    phone: auth.verityAccountNo,
    password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({
    password: '',
    confirm_password: '',
  });

  useEffect(() => {
    if(auth.changePassStatus === "success"){
      navigate("/change-password");
    }else{
      navigate("/forgot-password");
    }
  }, [auth.changePassStatus, navigate]);

  const validateForm = () => {
    const newErrors = {
        password: data.password ? '' : 'Password is required.',
        confirm_password:
            data.confirm_password === data.password
                ? ''
                : 'Passwords do not match.',
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setData({
        ...data,
        password: '',
        confirm_password: ''
      });
      dispatch(changePassword(data));
      navigate("/login");
    } else {
      console.log('error', 'Form contains errors. Please check!')
    }
  }
  
  
    return (
        <section className="md:px-10 2xl:px-20">
          <div className="min-h-screen flex align-middle items-center justify-center">
              
              <div className="w-full md:w-1/2 xl:w-1/3 h-92 border border-gray-200 shadow-lg p-5 rounded-md">
                <h3 className="font-semibold text-2xl text-[#006B2E] mb-4">Change Password</h3>
                <form onSubmit={handleSubmit}>
                <div className="w-full gap-5 mt-5">
                  <label className='text-slate-600' htmlFor="password">New Password *</label>
                  <input
                    type="password"
                    name="password"
                    placeholder='*****'
                    onChange={(e) => setData({ ...data, password: e.target.value})}
                    value={data.password}
                    className={`${ errors?.password ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                  />
                  <span className="text-sm text-red-600">{errors.password}</span>
                </div>
                <div className="w-full gap-5 mt-5">
                  <label className='text-slate-600' htmlFor="password">Confirm New Password *</label>
                  <input
                    type="password"
                    name="password"
                    placeholder='*****'
                    onChange={(e) => setData({ ...data, confirm_password: e.target.value})}
                    value={data.confirm_password}
                    className={`${ errors?.confirm_password ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                  />
                  <span className="text-sm text-red-600">{errors.confirm_password}</span>
                </div>
                <div className="grid mt-5">
                    <button
                        className="bg-[#006B2E] hover:bg-[rgb(73_53_72)] text-white py-2 rounded-md"
                        type="submit"
                    >
                        Submit
                    </button>
                    <div className="py-5 flex justify-between items-center gap-2">
                      <a href='/login' className="text-gray-600 hover:underline text-sm">Back to login?</a>
                    </div>
                </div>
                </form>
              </div>
          </div>
        </section>
    );
};

export default ChangePassword;
