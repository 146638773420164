import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { sendResetOtp, verifyPhone } from '../../features/authSlice';
import CountDown from '../CountDown';

const VerifyPhone = () => {
  const auth = useSelector((state) => state.auth);
  const initialTime = 60; // 1 minutes in seconds
  const [seconds, setSeconds] = useState(initialTime);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState({
    phone: auth.phone,
    otp_code: "",
  });
  const [errors, setErrors] = useState('');
  
  useEffect(() => {
    if (auth?.id) {
      if (auth?.status === "1" && auth?.phone_verified_at?.length > 4) {
        navigate("/customer/profile");
      }
      else if(auth?.status === "0" && auth?.phone_verified_at === "null")
      {
        if (auth?.phone) {
          dispatch(sendResetOtp(auth?.phone));
        }
        navigate("/verify-phone");
      }
    }
    else{
      navigate("/register");
    }
  }, [auth?.id, auth?.status, auth?.phone_verified_at, navigate]);

  const validateForm = () => {
    const newErrors = {
        otp: otp.otp_code ? '' : 'OTP code is required.',
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(verifyPhone(otp));
    } else {
      console.log('Form contains errors. Please check OTP!')
    }
  }

  const handleResend = () => {
    if (auth.phone) {
      dispatch(sendResetOtp(auth.phone));
    } else {
      console.log('Form contains errors. Please check OTP!')
    }
  }

    return (
        <section className="md:px-10 2xl:px-20">
          <div className="h-full flex align-middle items-center justify-center min-h-screen">
              <div className="w-full md:w-1/2 xl:w-1/3 h-92 border border-gray-200 shadow-lg p-5 rounded-md">
                <form onSubmit={handleSubmit}>
                <h3 className="font-semibold text-2xl text-[#006B2E] mb-2">Verify Phone Number</h3>
                <div className="w-full gap-5 mt-5">
                <label className='text-slate-600' htmlFor="note">Type OTP *</label>
                  <input
                    type="text"
                    name="phone"
                    placeholder='OTP'
                    onChange={(e) => setOtp({...otp, otp_code: e.target.value})}
                    className={`${ errors?.otp ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                  />
                  <p className="text-sm text-red-600 mb-0">{errors.otp}</p>
                </div>
                
                <div className="grid mt-5">
                {auth?.otpStatus === 'success' ? (
                  <button
                    className="bg-[#006B2E] hover:bg-[rgb(73_53_72)] text-white py-2 rounded-md"
                    type="submit"
                    >
                      Submit
                  </button>
                ) : (
                  <button
                  disabled
                        className="bg-[#2ca05f] text-white py-2 rounded-md"
                        type="submit"
                    >
                        Submit
                    </button>
                )}
                    
                </div>
                </form>
                <div className="py-5 flex justify-between items-center gap-2">
                {
                  auth?.otpStatus === 'success' ? (
                    <CountDown seconds={seconds} handleSubmit={handleResend} />
                  ) : (
                    <>
                    {
                      auth.status == 0 ? (
                        <button
                          onClick={handleResend}
                          className="bg-[rgb(73_53_72)] text-white py-1 px-2 rounded-md"
                        >
                          Resend
                        </button>
                      ) : (
                        <a href="/register" className='text-slate-700 text-sm hover:underline'>Back to register</a>
                      )
                    }
                    </>
                  )
                }
                </div>
              </div>
          </div>
        </section>
    );
};

export default VerifyPhone;
