import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TECollapse } from "tw-elements-react";

const Sidebar = ()=> {
  const [activeElement, setActiveElement] = useState("");
  const [preActiveElement, setPreActiveElement] = useState("element177");
  const categories = useSelector((state) => state.category?.categories);
  const [loading, setLoading] = useState(true);
  const { value } = useParams();

  const handleClick = (val) => {
    if (val === activeElement) {
      setActiveElement("");
    } else {
      setActiveElement(val);
    }
  };
  
  useEffect(() => {
    if (value && categories && categories.data && Array.isArray(categories.data)) 
    {
      const filteredData = categories.data.filter(category => category.slug === value);
      const childData = filteredData.map(category => category.child_categories.length);

      if (filteredData && Array.isArray(filteredData) && filteredData.length > 0) {
        if(childData > 0){
          const filteredIds = filteredData.map(category => category.id);
          setActiveElement(`element${filteredIds[0]}`);
        }else{
          const filteredChildIds = filteredData.map(category => category.parent_id);
          setActiveElement(`element${filteredChildIds[0]}`);
        }

      } else {
        setActiveElement("");
      }
    }
  }, [value, categories]);

  
  useEffect(() => {
    const timer = setTimeout(()=>{
      setLoading(false)
    }, 500)
  
    return () => clearTimeout(timer);
  }, [])

  return (
    <>
    {
      loading ? (
        <div className='pb-2 px-4 py-4 bg-slate-600 rounded min-h-[600px]'>
          <div className="bg-slate-400 w-full h-8 rounded"></div>
          <div className="categories mt-3">
            <div className='h-6 w-3/5 mt-4 rounded bg-slate-300'></div>
            <div className='h-6 w-3/5 mt-4 rounded bg-slate-300'></div>
            <div className='h-6 w-3/5 mt-4 rounded bg-slate-300'></div>
            <div className='flex w-full items-center mt-6'>
              <span className='h-6 w-3/4 rounded bg-slate-300'></span>
              <span className='ml-auto h-6 w-8 rounded bg-slate-300'></span>
            </div>
            <div className='flex w-full items-center mt-6'>
              <span className='h-6 w-3/4 rounded bg-slate-300'></span>
              <span className='ml-auto h-6 w-8 rounded bg-slate-300'></span>
            </div>
            <div className='h-6 w-3/5 mt-4 rounded bg-slate-300'></div>
            <div className='h-6 w-3/5 mt-4 rounded bg-slate-300'></div>
            <div className='h-6 w-3/5 mt-4 rounded bg-slate-300'></div>
            <div className='flex w-full items-center mt-6'>
              <span className='h-6 w-3/4 rounded bg-slate-300'></span>
              <span className='ml-auto h-6 w-8 rounded bg-slate-300'></span>
            </div>
          </div>
        </div>
      ) : (
        <div className='pb-2 px-4 py-4 bg-[rgb(73_53_72)] rounded min-h-[600px]'>
          <h3 className="text-slate-100 font-semibold text-2xl font-['sans-serif']">All Categories</h3>
          <div className="categories mt-1">
            <div className='w-full mb-1'>
              <a
                href='/categories/new-arrivals'
                className={`${
                  value === `new-arrivals` &&
                  `text-slate-100 bg-green-700`
                } cursor-pointer flex w-full items-center font-normal rounded p-1.5 text-lg text-slate-200 hover:bg-white hover:text-[#006B2E]`}
              >
                New Arrivals
              </a>
            </div>
            <div className='w-full mb-1'>
              <a
                href='/categories/sales-products'
                className={`${
                  value === `sales-products` &&
                  `text-slate-100 bg-green-700`
                } cursor-pointer flex w-full items-center font-normal rounded p-1.5 text-lg text-slate-200 hover:bg-white hover:text-[#006B2E]`}
              >
                Sales Products
              </a>
            </div>
            <div className='w-full mb-1'>
              <a
                href='/categories/discount-products'
                className={`${
                  value === `discount-products` &&
                  `text-slate-100 bg-green-700`
                } cursor-pointer flex w-full items-center font-normal rounded p-1.5 text-lg text-slate-200 hover:bg-white hover:text-[#006B2E]`}
              >
                Discount Products
              </a>
            </div>
          </div>
          {
            categories?.data.map((category, index)=> (
              <div key={index} className="categories mt-1">
                {
                  category?.child_categories?.length ? ( 
                    <div id="accordionExample" className='w-full mb-1'>
                    <a
                      type='button'
                      className={`${
                        activeElement === `element${category.id}` &&
                        `text-slate-800 bg-white rounded-b-none`
                      } flex w-full items-center font-normal rounded p-1.5 text-lg text-slate-200 hover:bg-white hover:text-[#006B2E]`}
                      onClick={() => handleClick(`element${category.id}`)}
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <a href={`/categories/${category?.slug}`}>
                      {category?.name}
                      </a>
                      <span
                        className={`${
                          activeElement === `element${category.id}`
                            ? `rotate-[-180deg] -mr-1`
                            : `rotate-0 fill-[#006B2E]`
                        } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none `}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="h-6 w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </span>
                    </a>
                    <TECollapse
                      show={activeElement === `element${category.id}`}
                      className="!mt-0 !rounded-t-none !shadow-none border-0 bg-slate-100"
                    >
                      <ul className="px-2 py-1 w-full">
                        {
                        category?.child_categories.map((child, i)=>(
                          <li key={i} className={`${
                            value === `${child?.slug}` &&
                            `text-slate-100 hover:text-green-800 bg-green-700`
                            } mb-1 py-1 hover:bg-white rounded`}>
                            <a className='' 
                              href={`/categories/${child?.slug}`}
                            >- {child.name}</a>
                          </li>
                          ))
                        }
                      </ul>
                    </TECollapse>
                </div>
                   ) : (
                    <>
                    {
                      category.parent_id < 1 && (
                        <div className='w-full mb-1'>
                      <a
                        href={`/categories/${category?.slug}`}
                        className={`${
                          value === `${category?.slug}` &&
                          `text-slate-100 bg-green-700`
                        } flex w-full items-center font-normal rounded p-1.5 text-lg text-slate-200 hover:bg-white hover:text-[#006B2E]`}
                        type="button"
                      >
                        {category?.name}
                      </a>
                    </div>
                      )
                    }
                    </>
                   )
                }
              </div>
            ))
          }
        </div>
      )
    }
    </>
  )
}

export default Sidebar
