import CheckIcon from '@mui/icons-material/Check';
import React, { useState } from 'react';
import { TECollapse } from "tw-elements-react";
import ssl from '../../images/payment/ssl.jpeg';


const DirectOrder = ({loading, data, dLoading, aData, paymentMethod, process, setProcess, formData, setFormData}) => {
  const [activeElement, setActiveElement] = useState("element1");
  const [isChecked, setIsChecked] = useState(null);
  const [isDChecked, setIsDChecked] = useState(null);
  const [isPChecked, setIsPChecked] = useState(null);
  const [singleData, setSingleData] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.id)
    setProcess({ ...process, dAddress: e})
  };

  const handleDCheckboxChange = (e) => {
    setIsDChecked(e.i)
    setProcess({ ...process, dAddress: e.item})
  };

  const handlePaymentChange = (val) => {
    setIsPChecked(val.id)
    setProcess({ ...process, payment: val.slug})
  };

  const handleClick = (value) => {
    if (value === activeElement) {
      setActiveElement("");
    } else {
      setActiveElement(value);
    }
  };

  const capitalizeData = (data) => {
    if (!data) return '';
    return data
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  }
  
  return (
  <div className="w-full xl:w-2/3">
      <h1 className="text-lg font-medium text-slate-600">Customer Details</h1>
      <div className="flex flex-wrap md:flex-nowrap items-center gap-5 mt-10">
      <ul className="relative m-0 w-full list-none overflow-hidden p-0 transition-[height] duration-200 ease-in-out">
        {/* <!--Customer Information--> */}
        <li
          className="relative h-fit after:absolute after:left-[2.45rem] after:top-[3.6rem] after:mt-px after:h-[calc(100%-2.45rem)] after:w-px after:bg-[#e0e0e0] after:content-['']">
          <div
          onClick={() => handleClick("element1")}
          aria-expanded="true"
          aria-controls="collapseOne"
            className="flex cursor-pointer items-center p-6 leading-[1.3rem] no-underline after:bg-[#e0e0e0] after:content-[''] focus:outline-none">
            {
              formData.name && formData.contact.length > 10 && formData.password && formData.address ? <>
              <span
                className="mr-3 flex h-[1.938rem] w-[1.938rem] items-center justify-center rounded-full bg-green-700 text-sm font-medium text-slate-100">
                <CheckIcon />
              </span>

              <span
                className="text-green-700">
                Customer Information
              </span>
              </>
              :
              <>
              <span
              className="mr-3 flex h-[1.938rem] w-[1.938rem] items-center justify-center rounded-full bg-slate-200 text-sm font-medium text-slate-500">
              1
            </span>

            <span
              className="text-slate-500">
              Customer Information
            </span>
              </>
            }
          </div>
          <TECollapse
            show={activeElement === "element1"}
            className="!mt-0 !rounded-b-none !shadow-none border-0"
          >
            <div className="mx-16 px-4 py-4 border border-slate-300 rounded flex flex-wrap gap-2">
              {/* info container  */}
              <>
                  <div className="w-full mb-2">
                      <label className="text-slate-600" htmlFor="name">Full Name*</label>
                      <br />
                      <input
                          type="text"
                          name="name"
                          placeholder='Type your name'
                          value={formData.name}
                          onChange={handleChange}
                          className="border border-[rgb(212_212_212)] w-full mt-2 rounded-md h-8 px-2"
                      />
                  </div>
                  <div className="w-full mb-2">
                      <label className="text-slate-600" htmlFor="contact">Phone*</label>
                      <br />
                      <input
                          type="number"
                          name="contact"
                          placeholder='Type your phone no'
                          value={formData.contact}
                          onChange={handleChange}
                          className="border border-[rgb(212_212_212)] w-full mt-2 rounded-md h-8 px-2"
                      />
                        <br />
                      {
                      formData.contact.length > 0 && formData.contact.length < 11 ?
                      <span className='text-xs text-red-600'>Phone must be valid & 11 digits! </span> : ''
                      }
                  </div>
                  <div className="w-full mb-2">
                      <label className="text-slate-600" htmlFor="password">Password*</label>
                      <br />
                      <input
                          type="password"
                          name="password"
                          placeholder='*****'
                          value={formData.password}
                          onChange={handleChange}
                          className="border border-[rgb(212_212_212)] w-full mt-2 rounded-md h-8 px-2"
                      />
                      <br />
                      {
                      formData.password.length > 0 && formData.password.length < 6 ?
                      <span className='text-xs text-red-600'>Password min 6 characters! </span> : ''
                      }
                  </div>
                  <div className="w-full">
                      <label className="text-slate-600" htmlFor="address">Address*</label>
                      <br />
                      <input
                          type="text"
                          name="address"
                          placeholder='Type your address'
                          value={formData.address}
                          onChange={handleChange}
                          className="border border-[rgb(212_212_212)] w-full mt-2 rounded-md h-8 px-2"
                      />
                  </div>
              </>
              
            </div>
            {
              formData.name && formData.contact.length > 10 && formData.password && formData.address ? 
              <button
              onClick={() => handleClick("element2")}
              aria-expanded="true"
              aria-controls="collapseOne"
              className="mx-16 mt-2 px-3 py-1 rounded text-slate-100 bg-green-700 hover:bg-green-800 transition duration-400">Continue</button>
              :
              <button
              disabled
              className="mx-16 mt-2 px-3 py-1 rounded text-slate-100 bg-slate-400">Continue</button>
            }
          </TECollapse>
        </li>

        {/* <!--delivery address--> */}
        <li
          className="relative h-fit after:absolute after:left-[2.45rem] after:top-[3.6rem] after:mt-px after:h-[calc(100%-2.45rem)] after:w-px after:bg-[#e0e0e0] after:content-['']">
          <div
            onClick={formData.name && formData.contact.length > 10 && formData.password && formData.address ? (() => handleClick("element2")) :  (() => handleClick("element1")) }
            aria-expanded="true"
            aria-controls="collapseTwo"
            className="flex cursor-pointer items-center p-6 leading-[1.3rem] no-underline focus:outline-none ">
              {
              process?.dAddress ? <>
              <span
                className="mr-3 flex h-[1.938rem] w-[1.938rem] items-center justify-center rounded-full bg-green-700 text-sm font-medium text-slate-100">
                <CheckIcon />
              </span>

              <span
                className="text-green-700">
                 Delivery Address
              </span>
              </>
              :
              <>
                <span
                  className="mr-3 flex h-[1.938rem] w-[1.938rem] items-center justify-center rounded-full bg-slate-200 text-sm font-medium text-slate-500">
                  2
                </span>
                <span
                  className="text-slate-500">
                  Delivery Address
                </span>
              </>
            }
          </div>
          <TECollapse
            show={activeElement === "element2"}
            className="!mt-0 !rounded-b-none !shadow-none border-0"
          >
            <div className="mx-16 px-4 py-8 border border-slate-300 rounded flex flex-wrap gap-2">
              {/* info container  */}
              {
                loading ? <>
                <div className="border border-slate-300 bg-slate-100 px-4 py-2 w-52 min-h-24 rounded">
                  <div className="flex items-center justify-between">
                    <h3 className="w-[80%] rounded-md h-7 bg-slate-400"></h3>
                    <div className="mb-[0.125rem] h-6 w-5 bg-slate-400 rounded-md block min-h-[1.5rem] pl-[1.5rem]"></div>
                  </div>
                  <p className="mt-1 w-full h-5 bg-slate-400 rounded-md"></p>
                  <p className="mt-1 w-32 h-5 bg-slate-400 rounded-md"></p>
                  <p className="mt-1 w-20 h-5 bg-slate-400 rounded-md"></p>
                </div>
                </> : <>
                {
                  data?.data?.map((item, i)=>(
                    <div key={i} className="border border-slate-300 px-4 py-2 w-52 min-h-24 rounded">
                      <div className="flex items-center justify-between">
                        <h3 className="font-normal text-slate-600">{item.title}</h3>
                        <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                          <input
                            className="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-neutral-400 outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-green-700 checked:bg-green-700 checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                            type="checkbox"
                            id="checkboxCheckedNoLabel"
                            value="address"
                            checked={isChecked===item.id ? true : false}
                            onChange={() => handleCheckboxChange(item)}
                            />
                        </div>
                      </div>
                      <p className="mt-1 text-slate-500 text-sm">
                      {item.description}
                      </p>
                      <p className="mt-1 text-slate-500 text-sm">
                        {item.cost} BDT
                      </p>
                    </div>
                  ))
                }
                </>
              }
              
            </div>
            {
              process.dAddress ? 
              <button
              onClick={() => handleClick("element3")}
              aria-expanded="true"
              aria-controls="collapseOne"
              className="mx-16 mt-2 px-3 py-1 rounded text-slate-100 bg-green-700 hover:bg-green-800 transition duration-400">Continue</button>
              :
              <button
              disabled
              className="mx-16 mt-2 px-3 py-1 rounded text-slate-100 bg-slate-400">Continue</button>
            }
          </TECollapse>
        </li>

        {/* <!--Payment information--> */}
        <li
          className="relative h-fit">
          <div
            onClick={process?.dAddress ? (() => handleClick("element3")) : (() => handleClick("element1"))}
            aria-expanded="true"
            aria-controls="collapseThree"
            className="flex cursor-pointer items-center p-6 leading-[1.3rem] no-underline focus:outline-none ">
              {
              process?.payment ? <>
              <span
                className="mr-3 flex h-[1.938rem] w-[1.938rem] items-center justify-center rounded-full bg-green-700 text-sm font-medium text-slate-100">
                <CheckIcon />
              </span>

              <span
                className="text-green-700">
                 Payment Method
              </span>
              </>
              :
              <>
                <span
                  className="mr-3 flex h-[1.938rem] w-[1.938rem] items-center justify-center rounded-full bg-slate-200 text-sm font-medium text-slate-500">
                  3
                </span>
                <span
                  className="text-slate-500">
                  Payment Method
                </span>
              </>
            }
          </div>
          <TECollapse
            show={activeElement === "element3"}
            className="!mt-0 !rounded-b-none !shadow-none border-0"
          >
            <div className="mx-16 px-4 py-4 border border-slate-300 rounded flex flex-wrap gap-2">
              {/* info container  */}
              <div className="flex flex-col gap-2 mt-3">
                {/* {
                  paymentMethod?.map((item, i)=>(
                    <div key={i}>
                      <input
                          type="radio"
                          name="payment"
                          onChange={()=>handlePaymentChange(item, i)}
                          checked={isPChecked===item.id ? true : false}
                          className="hue-rotate-[150deg] scale-125"
                      />
                      <label
                          htmlFor="payment"
                          className="pl-2 md:pl-4 text-sm md:text-base"
                      >
                          {item?.name}
                          {item?.slug === 'ssl' && <img className='h-24' src={ssl} alt="Payment Details" />}
                      </label>
                    </div>
                  ))
                } */}
                {
                  capitalizeData(process?.dAddress?.title) === "Inside Dhaka" ?
                  <>
                     {paymentMethod.map((item, i)=>(
                      <div key={i}>
                      <input
                          type="radio"
                          name="payment"
                          id="payment"
                          onChange={()=>handlePaymentChange(item, i)}
                          checked={isPChecked===item.id ? true : false}
                          className="hue-rotate-[150deg] scale-125"
                      />
                      <label
                          htmlFor="payment"
                          className="pl-2 md:pl-4 text-sm md:text-base"
                      >
                          {item?.name}
                          {item?.slug === 'ssl' && <img className='h-24' src={ssl} alt="Payment Details" />}
                      </label>
                    </div>
                    ))}
                  </>
                  :
                  (
                    paymentMethod?.filter(method => method.slug !== "cash").map((item, i)=>(
                      <div key={i}>
                      <input
                          type="radio"
                          name="payment"
                          id="payment"
                          onChange={()=>handlePaymentChange(item, i)}
                          checked={isPChecked===item.id ? true : false}
                          className="hue-rotate-[150deg] scale-125"
                      />
                      <label
                          htmlFor="payment"
                          className="pl-2 md:pl-4 text-sm md:text-base"
                      >
                          {item?.name}
                          {item?.slug === 'ssl' && <img className='h-24' src={ssl} alt="Payment Details" />}
                      </label>
                    </div>
                    ))
                  )
                  
                }
            </div>
            </div>
            
          </TECollapse>
        </li>
      </ul>
      </div>
  </div>
  )
}

export default DirectOrder
