/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import ProductsReviews from '../ProductsReviews/ProductsReviews';

const ProductDescription = ({product, del, setDel}) => {
    const reviewData = [
        {
            rating: 5,
            review: 78,
        },

        {
            rating: 4,
            review: 54,
        },
        {
            rating: 3,
            review: 14,
        },
        {
            rating: 2,
            review: 2,
        },
        {
            rating: 1,
            review: 5,
        },
    ];
    const [show, setShow] = useState('description');

    const descriptionShow = () => {
        setShow('description');
    };

    const reviewsShow = () => {
        setShow('reviews');
    };

    const [totalRating, setTotalRating] = useState(5);
    const [rating, setRating] = useState(4);

    const total = product?.reviews.reduce((acc, review) => acc + review.rating, 0);
    const avgRating = total / product?.reviews?.length;

    return (
        <section className="my-5 px-4 md:px-10 2xl:px-20">
            <div className="container mx-auto">
                <div className="flex flex-col lg:flex-row gap-5 items-start">
                    <div className="lg:w-1/3">
                        <div className="flex flex-col bg-[rgb(73_53_72)] w-64 py-10 px-8 rounded-md">
                            <div className="flex flex-col mb-10 items-center">
                                <h1 className="text-white text-5xl font-bold mb-5">
                                    <small className="text-lg font-semibold text-[#ece2eb]"> {avgRating} / 5</small>
                                </h1>
                                <div className="flex items-center gap-2">
                                {Array.from({ length: totalRating }).map((_, index) => (
                                  <span key={index}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                      className={`w-5 h-5 cursor-pointer ${
                                        index < avgRating ? 'text-[rgb(235_255_0)]' : 'text-gray-400'
                                      }`}
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                ))}
                                </div>
                                <small className="text-white mt-3">{product?.reviews?.length} { product?.reviews?.length > 1 ? 'Reviews' : 'Review'}</small>
                            </div>
                            
                        </div>
                    </div>
                    <div className="w-full lg:w-2/3">
                        <div className="flex flex-wrap bg-slate-100 py-5 px-5 rounded-md  gap-5 md:gap-12 items-center text-[#9f9f9f]">
                            <h1
                                onClick={descriptionShow}
                                className={`${
                                    show === 'description'
                                        ? 'text-[#006B2E]'
                                        : 'text-default'
                                } cursor-pointer font-bold `}
                            >
                                Description
                            </h1>
                            <h1
                                onClick={reviewsShow}
                                className={`${
                                    show === 'reviews' ? 'text-[#006B2E]' : 'text-default'
                                } cursor-pointer font-bold `}
                            >
                                Reviews
                            </h1>
                        </div>
                        {show === 'description' && (
                            <>
                                {' '}
                                <div className="mt-6 px-4" dangerouslySetInnerHTML={{ __html: product?.long_description }} />
                            </>
                        )}
                        {show === 'reviews' && <ProductsReviews product={product} del={del} setDel={setDel} />}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProductDescription;
