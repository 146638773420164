import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { app_url, setNHeaders, url } from '../../features/api';

const Blog = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${url}/blog`, setNHeaders())
     .then(response => {
       setData(response?.data);
     })
     .catch(err => {
       setError(err);
     })
     .finally(() => {
       setLoading(false);
     });
 }, []);

  return (
    <section className='min-h-screen py-6'>
      <div className="container mx-auto">
        <h2 className="text-slate-600 font-semibold text-2xl mt-5 mb-10 text-center">Latest News</h2>
        {
          loading ? <>
          <div className="content mb-6">
            <div className="grid grid-cols-3 grid-rows-3 gap-8">
              <div className="bg-slate-100 rounded-lg shadow-lg">
                <div className="grid grid-rows-3 grid-flow-col gap-2">
                  <div className="row-span-3 bg-slate-300 w-28"></div>
                  <div className="col-span-2 bg-slate-300 w-52 h-7 rounded-sm mb-1.5"></div>
                  <div className="row-span-2 col-span-2 bg-slate-300 h-24 rounded-sm"></div>
                </div>
              </div>
              <div className="bg-slate-100 rounded-lg shadow-lg">
                <div className="grid grid-rows-3 grid-flow-col gap-2">
                  <div className="row-span-3 bg-slate-300 w-28"></div>
                  <div className="col-span-2 bg-slate-300 w-52 h-7 rounded-sm mb-1.5"></div>
                  <div className="row-span-2 col-span-2 bg-slate-300 h-24 rounded-sm"></div>
                </div>
              </div>
              <div className="bg-slate-100 rounded-lg shadow-lg">
                <div className="grid grid-rows-3 grid-flow-col gap-2">
                  <div className="row-span-3 bg-slate-300 w-28"></div>
                  <div className="col-span-2 bg-slate-300 w-52 h-7 rounded-sm mb-1.5"></div>
                  <div className="row-span-2 col-span-2 bg-slate-300 h-24 rounded-sm"></div>
                </div>
              </div>
              <div className="bg-slate-100 rounded-lg shadow-lg">
                <div className="grid grid-rows-3 grid-flow-col gap-2">
                  <div className="row-span-3 bg-slate-300 w-28"></div>
                  <div className="col-span-2 bg-slate-300 w-52 h-7 rounded-sm mb-1.5"></div>
                  <div className="row-span-2 col-span-2 bg-slate-300 h-24 rounded-sm"></div>
                </div>
              </div>
            </div>
          </div>
          </> : <>
            <div className="grid grid-cols-3 grid-rows-3 gap-8">
            {
              data?.news.map((item, i) => (
              <div key={i} class="bg-slate-100 rounded-lg shadow-lg">
                <div className="flex gap-2 p-2">
                  <div className="w-32 h-28">
                    <img
                      className="rounded-md h-full"
                      src={`${app_url}/storage/${item?.thumbnail}`}
                      alt={item?.title}
                    />
                  </div>
                  <div className="flex-1">
                    <Link to={`/news/${item?.slug}/view`} className="font-semibold text-lg text-green-800 hover:text-green-600">{item?.title}</Link>
                    <div className="mt-1 line-clamp-3">{item?.description}</div>
                  </div>
                </div>
              </div>
              ))
            }
            </div>
          </>
        }

      </div>
    </section>
  )
}

export default Blog
