import React from 'react';
import feedback from '../../../images/Banner/feedback.svg';
import payment from '../../../images/Banner/payment.svg';
import pickup from '../../../images/Banner/pickup.svg';
import star from '../../../images/Banner/star.svg';

const Banner = () => {
    const bannerData = [
        {
            id: 1,
            img: pickup,
            title: 'Fast Delivery',
            tag: 'Max 24 hours',
        },
        {
            id: 2,
            img: star,
            title: 'Best Quality',
            tag: 'Brand',
        },
        {
            id: 3,
            img: feedback,
            title: 'Feedback',
            tag: '99% Real Data',
        },
        {
            id: 4,
            img: payment,
            title: 'Payment',
            tag: 'Secure',
        },
    ];
    return (
        <section className="my-10 px-4 md:px-10 2xl:px-20">
            <div className="container mx-auto flex flex-wrap items-center justify-center gap-5">
                {bannerData.map((data) => (
                    <div
                        className="shadow-[0px_3px_3px_0_rgb(0,0,0,6%)] hover:shadow-[rgba(0,0,0,0.24)_0px_3px_8px] px-20 py-16 rounded-md flex flex-col items-center justify-center gap-3 bg-slate-50"
                        key={data.id}
                    >
                        <img src={data.img} alt={data.title} />
                        <p className="text-sm mt-5 font-semibold">{data.title}</p>
                        <small className="text-xs">{data.tag}</small>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Banner;
