import LockIcon from '@mui/icons-material/Lock';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginUser } from '../../features/authSlice';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [user, setUser] = useState({
    phone: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    phone: '',
    password: '',
  });

  useEffect(() => {
    if (auth?.id) {
      if (auth?.phone_verified_at?.length > 4) {
        navigate("/customer/profile");
      }
      else {
        navigate("/verify-phone");
      }
      // if (auth?.status === '1' && auth?.phone_verified_at.length > 0) {
      //   navigate("/customer/profile");
      // }
      // else if(auth?.status === '1' && auth?.phone_verified_at === 'null')
      // {
      //   navigate("/login");
      // }
      // else if(auth?.status === '0' && auth?.phone_verified_at === 'null')
      // {
      //   navigate("/verify-phone");
      // }
    }
    else{
      navigate("/login");
    }
  }, [auth?.id, auth?.status, auth?.verifyStatus, auth?.phone_verified_at, navigate]);


  const validateForm = () => {
    const newErrors = {
      phone: user?.phone ? '' : 'Phone number is required.',
      password: user?.password ? '' : 'Password is required.',
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(loginUser(user));
    } else {
      toast.error("Form contains errors. Please check information.", {
        position: "top-right",
      });
    }
  }  

    return (
        <section className="md:px-10 2xl:px-20">
          <form onSubmit={handleSubmit}>
          <div className="min-h-screen flex align-middle items-center justify-center">
            <div className="w-full md:w-1/2 xl:w-1/3 h-92 border border-gray-200 shadow-lg p-5 rounded-md">
              <h3 className="font-semibold text-4xl text-[#006B2E] mb-2">Login</h3>
              <p className="mb-4 text-slate-600">Welcome Back!</p>

              <div className="w-full gap-5 mt-5">
                <label className='text-slate-600' htmlFor="note">Phone *</label>
                <input
                  type="text"
                  name="zip"
                  placeholder='Type your phone no'
                  onChange={(e) => setUser({ ...user, phone: e.target.value})}
                  className={`${ errors?.phone ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                />
                <span className="text-sm text-red-600">{errors.phone}</span>
              </div>
              <div className="w-full gap-5 mt-5">
                <label className='text-slate-600' htmlFor="password">Password *</label>
                <input
                  type="password"
                  name="password"
                  placeholder='*****'
                  onChange={(e) => setUser({ ...user, password: e.target.value})}
                  className={`${ errors?.password ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                />
                <span className="text-sm text-red-600">{errors.password}</span>
              </div>
                
                
              <div className="grid mt-5">
                  <button
                      className="bg-[#006B2E] hover:bg-[rgb(73_53_72)] text-white py-2 rounded-md"
                      type="submit"
                  >
                      Login
                  </button>
                  <div className="py-5 flex justify-between items-center gap-2">
                    <a href='/register' className="text-gray-600 hover:underline text-sm">Don't have an account?</a>
                    <a href='/forgot-password' className="text-gray-600 hover:underline text-sm"> <LockIcon/> Forgot Password</a>
                  </div>
              </div>
            </div>
          </div>
          </form>
        </section>
    );
};

export default Login;
