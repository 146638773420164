import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { sendResetOtp, verifyOtp } from '../../features/authSlice';
import CountDown from '../CountDown';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState('');
  const [otpErrors, setOtpErrors] = useState('');
  const initialTime = 60; // 1 minutes in seconds
  const [seconds, setSeconds] = useState(initialTime);
  const [otp, setOto] = useState({
    phone: "",
    otp_code: "",
  });
  
  const validateForm = () => {
    const newErrors = {
        phone: phone ? '' : 'Phone number is required.',
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };

  const validateOtpForm = () => {
    const newErrors = {
        otp: otp.otp_code ? '' : 'OTP code is required.',
    };

    setOtpErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSeconds(initialTime)
      setOto({...otp, phone: phone})
      dispatch(sendResetOtp(phone));
    } else {
      console.log('Form contains errors. Please check number!')
    }
  }

  const handleResendSubmit = () => {
    if (validateForm()) {
      setSeconds(initialTime)
      setOto({...otp, phone: phone})
      dispatch(sendResetOtp(phone));
    } else {
      console.log('Form contains errors. Please check number!')
    }
  }

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    if (validateOtpForm()) {
      dispatch(verifyOtp(otp));
    } else {
      console.log('Form contains errors. Please check OTP!')
    }
  }

  useEffect(() => {
    if(auth.changePassStatus === "success"){
      navigate("/change-password");
    }
  }, [auth.changePassStatus]);

  useEffect(() => {
    if (auth?.id) {
      if(auth?.phone_verified_at?.length > 4){
        navigate("/customer/profile");
      }else{
        navigate("/verify-phone");
      }
    }else{
      navigate("/login");
    }
  }, [auth?.id, auth?.status, auth?.verifyStatus, auth?.phone_verified_at, navigate]);

    return (
        <section className="md:px-10 2xl:px-20">
          <div className="h-full flex align-middle items-center justify-center min-h-screen">
              <div className="w-full md:w-1/2 xl:w-1/3 h-92 border border-gray-200 shadow-lg p-5 rounded-md">
                <h3 className="font-semibold text-2xl text-[#006B2E] mb-2">Forgot Password</h3>
                <form onSubmit={handleSubmit}>
                <div className="w-full flex items-center gap-4">
                  <input
                    type="text"
                    name="phone"
                    placeholder='Type your phone'
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    className={`${ errors?.phone ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                  />
                  <button
                      className="bg-[#006B2E] hover:bg-[rgb(73_53_72)] text-white w-20 h-10 mt-1 rounded text-sm"
                      type="submit"
                    >
                      Send
                  </button>
                </div>
                <p className="text-sm text-red-600 mb-0">{errors.phone}</p>
                
                {
                  auth?.otpStatus === 'success' && (
                    <CountDown seconds={seconds} handleSubmit={handleResendSubmit} />
                ) 
                }
                </form>
                
                <form onSubmit={handleOtpSubmit}>
                  <div className="w-full gap-5 mt-5">
                  <label className='text-slate-600' htmlFor="note">Type OTP *</label>
                    <input
                      type="text"
                      name="otp"
                      placeholder='OTP'
                      onChange={(e) => setOto({...otp, otp_code: e.target.value})}
                      className={`${ otpErrors?.otp ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                    />
                    <p className="text-sm text-red-600 mb-0">{otpErrors.otp}</p>

                  </div>
                
                  <div className="grid mt-5">
                      <button
                          className="bg-[#006B2E] hover:bg-[rgb(73_53_72)] text-white py-2 rounded-md"
                          type="submit"
                      >
                          Submit
                      </button>
                      <div className="py-5 flex justify-between items-center gap-2">
                        <a href='/login' className="text-gray-600 hover:underline text-sm">Back to login!</a>
                      </div>
                  </div>
                </form>
              </div>
          </div>
        </section>
    );
};

export default ForgotPassword;
