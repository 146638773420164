import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SettingsComponent from '../Shared/Customer/SettingsComponent';
import SideBar from '../Shared/Customer/SideBar';

const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth?.id) {
      if(auth?.phone_verified_at?.length > 4){
        navigate("/customer/settings");
      }else{
        navigate("/verify-phone");
      }
    }else{
      navigate("/login");
    }
  }, [auth?.id, auth?.status, auth?.verifyStatus, auth?.phone_verified_at, navigate]);

  return (
    <section className="my-10 px-4 md:px-10 2xl:px-20 min-h-screen">
      <div className="sm:container mx-auto">
        <div className="flex gap-10">
          <div className="hidden md:inline-block md:w-1/3 h-fit bg-slate-50 shadow-md px-4 py-4 rounded sticky top-40">
            <SideBar location={location} />
          </div>
          <div className="w-full sm:w-full">
            <SettingsComponent />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Settings
