import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { registerUser } from '../../features/authSlice';

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const [user, setUser] = useState({
    name: "",
    phone: "",
    password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    password: '',
    confirm_password: '',
  });

// console.log('user21', auth.user.id)

  useEffect(() => {
    if (auth?.id) {
      if (auth?.status === '1' && auth?.phone_verified_at?.length > 4) {
        navigate("/customer/profile");
      }
      else if(auth?.status === '1' && auth?.phone_verified_at === 'null')
      {
        navigate("/login");
      }
      else if(auth?.status === '0' && auth?.phone_verified_at === 'null')
      {
        navigate("/verify-phone");
      }
    }
    else{
      navigate("/register");
    }
  // }, [auth, navigate]); 
  // }, [auth.user, navigate]);
  }, [auth?.id, auth?.status, auth?.phone_verified_at, navigate]);

  const validateForm = () => {
    const newErrors = {
        name: user?.name ? '' : 'Name is required.',
        phone: user?.phone ? '' : 'Phone number is required.',
        password: user?.password ? '' : 'Password is required.',
        confirm_password:
            user?.confirm_password === user?.password
                ? ''
                : 'Passwords do not match.',
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(registerUser(user));
    } else {
      toast.error("Form contains errors. Please check information.", {
        position: "top-right",
      });
    }
  }


  
    return (
        <section className="md:px-10 2xl:px-20">
          <form onSubmit={handleSubmit}>
          <div className="min-h-screen flex align-middle items-center justify-center">
              <div className="w-full md:w-1/2 xl:w-1/3 h-92 border border-gray-200 shadow-lg p-5 rounded-md">
                <h3 className="font-semibold text-3xl text-center text-[#006B2E] uppercase mb-4">Register</h3>
                <div className="w-full gap-5 mt-5">
                  <label className='text-slate-600' htmlFor="note">Full Name *</label>
                  <input
                    type="text"
                    name="name"
                    placeholder='Type your name'
                    onChange={(e) => setUser({ ...user, name: e.target.value})}
                    className={`${ errors?.name ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                  />
                  <span className="text-sm text-red-600">{errors.name}</span>
                </div>

                <div className="w-full gap-5 mt-5">
                  <label className='text-slate-600' htmlFor="note">Phone *</label>
                  <input
                    type="text"
                    name="phone"
                    placeholder='Type your phone no'
                    onChange={(e) => setUser({ ...user, phone: e.target.value})}
                    className={`${ errors?.phone ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                  />
                  <span className="text-sm text-red-600">{errors.phone}</span>
                </div>
                <div className="w-full gap-5 mt-5">
                  <label className='text-slate-600' htmlFor="password">Password *</label>
                  <input
                    type="password"
                    name="password"
                    placeholder='*****'
                    onChange={(e) => setUser({ ...user, password: e.target.value})}
                    className={`${ errors?.password ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                  />
                  <span className="text-sm text-red-600">{errors.password}</span>
                </div>

                <div className="w-full gap-5 mt-5">
                  <label className='text-slate-600' htmlFor="confirm_password">Confirm Password *</label>
                  <input
                    type="password"
                    name="confirm_password"
                    placeholder='*****'
                    onChange={(e) => setUser({ ...user, confirm_password: e.target.value})}
                    className={`${ errors?.confirm_password ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                  />
                  <span className="text-sm text-red-600">{errors.confirm_password}</span>
                </div>
                  
                  <div className="grid mt-5">
                      <button
                          className="bg-[#006B2E] hover:bg-[rgb(73_53_72)] text-white py-2 rounded-md"
                          type="submit"
                      >
                          {auth.registerStatus === "pending" ? "Submitting..." : "Register"}
                      </button>
                      <div className="py-5 flex justify-between items-center gap-2">
                        <a href='/login' className="text-gray-600 hover:underline text-sm">Already have an account?</a>
                      </div>
                  </div>
              </div>
          </div>
          </form>
        </section>
    );
};

export default Register;
