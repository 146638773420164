import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { app_url, setNHeaders, url } from '../../features/api';

const BlogDetails = () => {
  const { slug } = useParams();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${url}/blog/${slug}/view`, setNHeaders)
     .then(response => {
       setData(response?.data);
     })
     .catch(err => {
       setError(err);
     })
     .finally(() => {
       setLoading(false);
     });
 }, []);

 function formatDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
      console.error(`Invalid date: ${dateString}`);
      return null;
  }
  return date.toISOString().split('T')[0];
}

  return (
    <section className='min-h-screen py-6'>
      <div className="container mx-auto">
        <h2 className="text-slate-600 font-semibold text-2xl mt-5 mb-10 text-center">News Details</h2>
        {
          loading ? <>
          <div className="content mb-6">
            <h3 className="bg-slate-500 w-64 h-7 rounded-sm mb-1.5"></h3>
            <p className="bg-slate-400 w-2/3 h-5 rounded-sm mb-1"></p>
            <p className="bg-slate-400 w-full h-5 rounded-sm mb-1"></p>
            <p className="bg-slate-400 w-2/4 h-5 rounded-sm"></p>
          </div>
          </> : <>
              <div className="content w-1/2 mx-auto">
              <a className="flex justify-end text-red-500 hover:text-red-600 " href="/news">Back</a>
              </div>
              <div className="content w-1/2 shadow-md py-2 px-4 bg-slate-50 mx-auto">
                <div className="top flex gap-6">
                    <img
                      className="w-32 h-28 rounded-md"
                      src={`${app_url}/storage/${data?.blog?.thumbnail}`}
                      alt={data?.blog?.title}
                    />
                    <h3 className="text-slate-600 font-medium text-xl mb-1">{data?.blog?.title}</h3>
                </div>
                <div className="des mt-3">
                  <p className="text-slate-500 text-base">Created : {formatDate(data?.blog?.created_at)}</p>
                  <p className="text-slate-600 text-lg mt-2">{data?.blog?.description}</p>
                </div>
              </div>
          </>
        }

      </div>
    </section>
  )
}

export default BlogDetails
