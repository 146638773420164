import axios from "axios";
import React, { useEffect, useState } from 'react';
import { app_url, setNHeaders, url } from '../../features/api';
import './Style.css';

const About = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    axios.get(`${url}/extra-pages`, setNHeaders())
     .then(response => {
       setData(response?.data?.res?.about);
       setHtmlContent(response?.data?.res?.about?.body);
     })
     .catch(err => {
       setError(err);
     })
     .finally(() => {
       setLoading(false);
     });
 }, []);

  return (
    <section className='min-h-screen py-6'>
      {
        loading ? 
        <>
        <div className="container mx-auto w-full h-[300px] rounded-lg bg-slate-600 shadow-md">
          <div className="flex w-full h-full items-center bg-[#16161663] p-12 pb-12 rounded-lg">
            <h1 className="w-44 h-12 mx-auto bg-slate-400 rounded-md"></h1>
          </div>
        </div>
        
        <div className="container mx-auto py-8">
          <div className="w-2/5 h-7 bg-slate-400 rounded-md mt-4"></div>
          <div className="w-full h-4 bg-slate-300 rounded-md mt-2"></div>
          <div className="w-full h-4 bg-slate-300 rounded-md mt-2"></div>
          <div className="w-full h-4 bg-slate-300 rounded-md mt-2"></div>
          <div className="w-full h-4 bg-slate-300 rounded-md mt-2"></div>
          <div className="w-full h-4 bg-slate-300 rounded-md mt-2"></div>
          <div className="w-full h-4 bg-slate-300 rounded-md mt-2"></div>
          <div className="w-full h-4 bg-slate-300 rounded-md mt-2"></div>
        </div>
        </> : 
        <>
          <div style={{ background: `url(${app_url}/storage/${data?.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} className="container mx-auto w-full h-[300px] rounded-lg bg-slate-600 shadow-md">
            <div className="flex w-full h-full items-center bg-[#16161663] p-12 pb-12 rounded-lg">
              <h1 className="flex-1 text-center font-semibold text-5xl text-slate-100">{data?.title}</h1>
            </div>
          </div>
          
          <div className="container mx-auto py-8">
            <div className="extra_page__css" dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        </>
      }
      
    </section>
  )
}

export default About
